<template>
  <ConfigProvider :theme="themeConfig" :locale="locale">
    <router-view></router-view>
  </ConfigProvider>
</template>

<script>
import { defineComponent } from 'vue';
import { ConfigProvider } from 'ant-design-vue';
import zhCN from 'ant-design-vue/es/locale/zh_CN';

import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');

export default defineComponent({
  name: 'App',
  components: {
    ConfigProvider,
  },
  data() {
    return {
      themeConfig: {
        token: {
          colorPrimary: '#669933', // 自定义主题色
          colorLink: '#669933',
          colorLinkHover: '#669933',
          borderRadiusBase: '4px',
        },
      },
      locale: zhCN, // 将 zhCN 赋值给 locale
    };
  },
});
</script>

<style>
/* 可以在这里添加全局样式 */
</style>
