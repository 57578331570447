<template>
  <a-layout>
    <a-layout-header>
      <div class="nd-layout_container nd-layout_normal nd-flex space-between">
        <div class="logo">
          <a href="/"><img src="@/assets/images/logo.png" alt="logo"></a>
        </div>

        <div class="text_content">
          <span class="text-green">公告：</span>
          <a href="/account/notice">{{ Notice }}</a>
        </div>

      </div>

      <!-- 顶部菜单 -->
      <div class="menu_bar">
        <div class="nd-layout_container nd-layout_normal nd-flex space-between">
          <ul class="menu_ul">
            <li :class="highlightNavItem(route)" v-for="route in menu" :key="route.key">
              <a :href="route.path">{{ route.name }}</a>
            </li>

          </ul>
          <a-popover placement="bottomRight" trigger="click">
            <template #content>

              <div style="width: 250px;">
                账户信息
                <p :style="{ color: `#669933` }">{{ LoginId }}</p>
                <a-divider />
                销售经理
                <p :style="{ color: `#669933` }">{{ SaleManager }} {{ SaleManagerTel }}</p>
                <a-divider />
                <a-statistic :title="`挂账额度(${TradingCurrency})`" :precision="2" :value="Credit" />
                <a-divider />

                <a-statistic :title="`剩余额度(${TradingCurrency})`" :precision="2" :value="MemberCreditUsed" />
                <a-divider />
                <a-row>
                  <a-col :span="10"><a-button href="/account/info"><template #icon>
                        <UserOutlined />
                      </template>
                      个人中心</a-button></a-col>
                  <a-col :span="11" :offset="3"><a-button @click="logout"><template #icon>
                        <PoweroffOutlined />
                      </template>
                      退出登录</a-button></a-col>
                </a-row>

              </div>

            </template>

            <a-button type="text" style="color: #fff;"> {{ MemberLinker }}<template #icon>
                <UserOutlined />
              </template></a-button>
          </a-popover>

        </div>
      </div>

    </a-layout-header>

    <a-layout-content>
      <router-view />
    </a-layout-content>

    <a-layout-footer>
      <div class="CommonFooter">
        <div class="nd-layout_container nd-layout_normal footer_top">
          <ul class="footer_top_1">
            <li>
              <h3>关于我们</h3>
            </li>
            <li><a href="about.html" target="_blank">关于我们</a></li>
            <li><a href="serviceterm.html" target="_blank">服务政策</a></li>
            <li><a href="apply.html" target="_blank">合作协议</a></li>
          </ul>
          <ul class="footer_top_1">
            <li>
              <h3>加盟合作</h3>
            </li>
            <li><a href="http://www.szcqly.com/partner/" target="_blank">合作伙伴</a></li>
            <li><a href="http://www.szcqly.com/job/job.php" target="_blank">诚聘英才</a></li>
            <!-- <li><a href="#">同业预定平台</a></li> -->
            <!-- <li><a href="#">API分销平台</a></li> -->
          </ul>
          <ul class="footer_top_1">
            <li>
              <h3>联系邮箱</h3>
            </li>
            <li>分销商合作：<a href="mailto:szcq@etxing.com">szcq@etxing.com</a></li>
            <li>供应商合作：<a href="mailto:szcq_travel@vip.163.com">szcq_travel@vip.163.com</a></li>
          </ul>
          <ul class="footer_top_1">
            <li>
              <h3>客服电话</h3>
            </li>
            <li>电话：0755-82283588</li>
            <li>传真：0755-25118327</li>
          </ul>
          <ul class="footer_top_1">
            <li>
              <h3>微信公众号</h3>
            </li>
            <li><img src="https://img.etxing.com/up/image/20170731/20170731162540_5244.jpg"
                style="width:82px; height:82px;" alt=""></li>
          </ul>
          <ul class="footer_top_1">
            <li>
              <h3>微信小程序</h3>
            </li>
            <li><img src="@/assets/images/B2BWechat.jpg" style="width:82px; height:82px;" alt=""></li>
          </ul>

        </div>

        <div class=" nd-layout_container nd-layout_normal footer_down">
          <img src="@/assets/images/logo.png" alt="">
          <span class="footer_text" v-html="copyRight"></span>
        </div>
      </div>
    </a-layout-footer>
  </a-layout>
  <a-back-top />
</template>
<script>
import Cookies from 'js-cookie';
import { UserOutlined, PoweroffOutlined } from '@ant-design/icons-vue';
import router from "@/assets/router";
export default {
  name: 'HeaderInner',
  data() {
    return {
      currentPath: window.location.pathname,
      menu: [
        {
          key: "hotel",
          path: "/hotels",
          name: "酒店预定",
        },
        {
          key: "order",
          path: "/order/list",
          name: "订单管理",
        },
        {
          key: "hotelgroup",
          path: "/hotelgroup",
          name: "团房定制",
        },
        {
          key: "hoteltravel",
          path: "/#",
          name: "旅游定制",
        },
        {
          key: "hotelmetting",
          path: "/#",
          name: "会务定制",
        },
        {
          key: "notice",
          path: "/account/notice",
          name: "通知公告",
        }
      ],
      copyRight: "Copyright@2017 - 2024 http://www.etxing.com 《壹同行》高端酒店预订B2B平台，是深圳春秋国旅旗下平台，并拥有所有权。 <a href='https://beian.miit.gov.cn' target='_blank'>粤ICP备11070406号</a> 公安备案号44030302000853",
      memberData: null,
      showTooltip: false,
      LoginId: "",
      SaleManager: "",
      SaleManagerTel: "",
      Credit: 0,
      TempCredit: 0,//临时额度
      MemberLinker: "",
      TradingCurrency: "CNY",
      MemberCreditUsed: 0,
      Notice: "",
      sharedData: 111
    }
  },

  components: {
    UserOutlined,
    PoweroffOutlined
  },
  computed: {

  },
  created() {
    //console.log(this.currentPath)
    setTimeout(() => {
      // 这里可以是实际的 AJAX 请求
      this.innerData = 'Fetched data from server';
    }, 2000); // 模拟 2 秒的请求延迟


    this.fetchMemberInfo();
  },
  methods: {
    async fetchMemberInfo() {
      try {

        var memberCacher = Cookies.get('etxing_' + Cookies.get('token'));
        //console.log(memberCacher)
        if (memberCacher != undefined) {
          //console.log("缓存中")
          this.memberData = JSON.parse(memberCacher);
          //console.log(this.memberData)
          this.Credit = this.memberData.MemberTempCredit
          this.MemberCreditUsed = this.memberData.MemberCreditUsed
          this.TradingCurrency = this.memberData.Member.TradingCurrency
          this.MemberLinker = this.memberData.Member.Name
          this.LoginId = this.memberData.MemberContacts[0].LoginId
          this.SaleManager = this.memberData.Member.SaleManager
          this.SaleManagerTel = this.memberData.Member.SaleManagerTel
          this.Notice = this.memberData.Notice.NewsTitle
          return;
        }


        const response = await this.$axios.get('/member');
        const data = response.data;
        delete data.Company;

        Cookies.set('etxing_' + Cookies.get('token'), JSON.stringify(data), { expires: 1 }); // 1天过期
        this.memberData = data;

        this.Credit = this.memberData.MemberTempCredit
        this.MemberCreditUsed = this.memberData.MemberCreditUsed
        this.TradingCurrency = this.memberData.Member.TradingCurrency
        this.MemberLinker = this.memberData.Member.Name
        this.LoginId = this.memberData.MemberContacts[0].LoginId
        this.SaleManager = this.memberData.Member.SaleManager
        this.SaleManagerTel = this.memberData.Member.SaleManagerTel
        this.Notice = this.memberData.Notice.NewsTitle

      } catch (error) {
        console.log(error)
        this.logout()
        //console.error('获取数据失败:', error.response.data.RetMessage);
      }
    },
    logout: function () {
      Cookies.remove('etxing_' + Cookies.get('token'))
      Cookies.remove('token')
      router.push('/')
    },
    highlightNavItem(route) {
      // 当前选中的路由与导航项匹配时添加高亮类
      //console.log(route.key)
      if (this.currentPath.indexOf(route.key) > 0) {
        return 'active';
      }
      return '';
    },

  }
}
</script>


<style scoped>
.text_content {
  line-height: 24px;
  font-size: 12px;
}


.ant-layout .ant-layout-header {
  background: #fff;
  height: 100%;
}

.ant-layout .ant-layout-header .ant-menu {
  line-height: 85px;
  margin-right: 100px !important;
  float: right
}


.menu_bar {
  width: 100%;
  height: 50px;

  background: #669933;
}

.menu_bar .menu_ul {
  display: flex;
  height: 50px;
  line-height: 50px;
  background: #669933;

}

.menu_bar .menu_ul li {
  width: 90px;
  height: 50px;
  text-align: center;
}

.menu_bar .menu_ul li a {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 14px;
  color: #fff;
  display: inline-block;
}

.menu_bar .menu_ul li :hover {
  background: #3D6218;
}

.menu_bar .active {
  background: #3D6218;
}



.CommonFooter .footer_down::before {
  content: '';
  width: 1164px;
  height: 1px;
  background: #6b6b6b;
  position: absolute;
  top: 10px;
}
</style>