// utils.js

// 获取字符串的字节长度
export function getByteLength(str) {
  let byteLength = 0;
  for (let char of str) {
    const codePoint = char.codePointAt(0);
    if (codePoint <= 0x7F) {
      byteLength += 1;
    } else if (codePoint <= 0x7FF) {
      byteLength += 2;
    } else if (codePoint <= 0xFFFF) {
      byteLength += 3;
    } else {
      byteLength += 4;
    }
  }
  return byteLength;
}


export function arrayChanged(newArr, oldArr) {
  if (newArr.length !== oldArr.length) {
    return true;
  }
  for (let i = 0; i < newArr.length; i++) {
    if (JSON.stringify(newArr[i]) !== JSON.stringify(oldArr[i])) {
      return true;
    }
  }
  return false;
}


export function extractTimestamp(dateString) {
  // 使用正则表达式匹配时间戳
  const match = dateString.match(/\d+/);

  if (match) {
    // 转换为数字
    return parseInt(match[0], 10);
  } else {
    throw new Error('Invalid date string format');
  }
}


//公共方法类
// utils.js

const Utils = {
  /**
   * 获取字符串的字节长度
   * @param {string} str - 输入字符串
   * @returns {number} 字节长度
   */
  getByteLength(str) {
    let byteLength = 0;
    for (let char of str) {
      const codePoint = char.codePointAt(0);
      if (codePoint <= 0x7F) {
        byteLength += 1;
      } else if (codePoint <= 0x7FF) {
        byteLength += 2;
      } else if (codePoint <= 0xFFFF) {
        byteLength += 3;
      } else {
        byteLength += 4;
      }
    }
    return byteLength;
  },

  /**
   * 比较两个数组是否发生变化
   * @param {Array} newArr - 新数组
   * @param {Array} oldArr - 旧数组
   * @returns {boolean} 是否发生变化
   */
  arrayChanged(newArr, oldArr) {
    if (newArr.length !== oldArr.length) {
      return true;
    }
    for (let i = 0; i < newArr.length; i++) {
      if (JSON.stringify(newArr[i]) !== JSON.stringify(oldArr[i])) {
        return true;
      }
    }
    return false;
  },

  /**
   * 提取字符串中的时间戳
   * @param {string} dateString - 包含时间戳的字符串
   * @returns {number} 时间戳
   */
  extractTimestamp(dateString) {
    const match = dateString.match(/\d+/);
    if (match) {
      return parseInt(match[0], 10);
    } else {
      throw new Error('Invalid date string format');
    }
  },

  /**
 * 生成 TraceId
 * @returns {string} TraceId
 */
  createTraceId() {
    let traceId = this.generateUUID();
    localStorage.setItem('TraceId', traceId);
    return traceId;
  },

  /**
   * 获取或生成 TraceId
   * @returns {string} TraceId
   */
  getTraceId() {
    let traceId = localStorage.getItem('TraceId');
    if (!traceId) {
      traceId = this.generateUUID();
      localStorage.setItem('TraceId', traceId);
    }
    return traceId;
  },

  /**
   * 生成 UUID
   * @returns {string} UUID
   */
  generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
};

export default Utils;
