import { createApp } from 'vue';
import App from './App.vue';
import router from '@/assets/router'
import axios from '@/assets/js/axios'; // 引入 Axios 实例
//import store from './store';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import '@/assets/styles/common.css'

const app = createApp(App);
app.config.globalProperties.$isLogin =false;//暂时用不到？
app.config.globalProperties.$axios = axios;
app.use(router);
//app.use(store); 
app.use(Antd);   
app.mount('#app')  

