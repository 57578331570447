<template>
  <section class="home_banner_section">
    <a-carousel autoplay v-model="bannerActive" height="500px" :style="{ backgroundColor: `#FFF` }">
      <div :key="index" :id="index" v-for="(item, index) in bannerCarousels">
        <img class="carousel_img" :src="item.NewsPhoto" />
      </div>
    </a-carousel>
  </section>
  <section class="hotel_group_section">
    <div class="group_bady">
      <div class="group_title">
        <h2><i class="iconfont icontianxie"></i>填写团房需求&nbsp;<span>24小时团房咨询热线&nbsp;0755-82321158</span></h2>
        <p>提交需求后,我们将在10分钟内回电您,确认需求并提供方案!</p>
      </div>

      <div class="group_form">
        <!-- Swiper -->
        <div class="group_img">
          <div class="swiper-container">
            <img id="Group" :src="groupImg" alt="" />
          </div>
        </div>
        <div>
          <a-form :model="form" ref="formRef" :rules="rules">
            <a-form-item label="地址" :label-col="{ span: 4 }">
              <a-row style="display: flex; gap: 8px;">
                <a-col :span="6" style="flex: 0 0 150px;">
                  <a-form-item :name="'country'">
                    <a-select v-model:value="form.country" @change="onCountryChange" placeholder="选择国家"
                      :disabled="countries.length === 0">
                      <a-select-option v-for="country in countries" :key="country.value" :value="country.value">{{
                        country.label }}</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>

                <a-col :span="6" style="flex: 0 0 150px;">
                  <a-form-item :name="'province'">
                    <a-select v-model:value="form.province" :disabled="!form.country" @change="onProvinceChange"
                      placeholder="选择省份">
                      <a-select-option v-for="province in provinces" :key="province.value" :value="province.value">{{
                        province.label }}</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>

                <a-col :span="6" style="flex: 0 0 150px;">
                  <a-form-item :name="'city'">
                    <a-select v-model:value="form.city" :disabled="!form.province" @change="onCityChange"
                      placeholder="选择城市">
                      <a-select-option v-for="city in cities" :key="city.value" :value="city.value">{{ city.label
                        }}</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>

                <a-col :span="6" style="flex: 0 0 150px;">
                  <a-form-item :name="'area'">
                    <a-select v-model:value="form.area" :disabled="!form.city" placeholder="选择区域">
                      <a-select-option v-for="area in areas" :key="area.value" :value="area.value">{{ area.label
                        }}</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form-item>
            <!-- 入住日期和退房日期 -->
            <a-form-item label="入住日期" :label-col="{ span: 4 }">
              <a-row :gutter="[50]">
                <a-col :span="8">
                  <a-form-item :name="'checkInDate'">
                    <a-date-picker v-model:value="form.checkInDate" placeholder="选择入住日期" format="YYYY-MM-DD"
                      value-format="YYYY-MM-DD" :disabled-date="disabledCheckInDate" style="width: 100%;" />
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="退房日期" :label-col="{ span: 8 }" name="checkOutDate">
                    <a-date-picker v-model:value="form.checkOutDate" placeholder="选择退房日期" format="YYYY-MM-DD"
                      value-format="YYYY-MM-DD" :disabled-date="disabledCheckOutDate" :disabled="!form.checkInDate"
                      style="width: 100%;" />
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form-item>
            <!-- 预算金额 团队性质-->
            <a-form-item label="预算金额" :label-col="{ span: 4 }">
              <a-row>
                <a-col :span="8">
                  <a-form-item name="budget">
                    <a-input-number v-model:value="form.budget" min="0" style="width: 90%;" placeholder="请输入预算金额" />
                    <span class="bed">元</span>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="团队性质" :label-col="{ span: 8 }" name="teamNature">
                    <a-select v-model:value="form.teamNature" placeholder="请选择团队性质" style="width: 100%;">
                      <!-- <a-select-option value="0">请选择</a-select-option> -->
                      <a-select-option value="1">政府团</a-select-option>
                      <a-select-option value="2">公司会议团</a-select-option>
                      <a-select-option value="3">旅游团</a-select-option>
                      <a-select-option value="4">转机团</a-select-option>
                      <a-select-option value="5">其他（需要沟通报备）</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form-item>
            <!-- 入住人数 房间数量-->
            <a-form-item label="入住人数" :label-col="{ span: 4 }">
              <a-row>
                <a-col :span="8">
                  <a-form-item name="checkInPerson">
                    <a-input-number v-model:value="form.checkInPerson" min="1" step="1" style="width: 100%;"
                      placeholder="请输入入住人数" />
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="房间数量" :label-col="{ span: 8 }" name="roomQuantity">
                    <a-input-number v-model:value="form.roomQuantity" min="1" style="width: 100%;"
                      placeholder="请输入房间数量" />
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form-item>
            <!-- 床型要求-->
            <a-form-item label="床型要求" :label-col="{ span: 4 }">
              <a-row>
                <a-col :span="8">
                  <a-form-item name="bedType">
                    <a-input v-model:value="form.bedType" placeholder="请输入床型要求" style="width: 80%;" />
                    <span style="margin-left: 8px;">大床</span>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="双床" :label-col="{ span: 8 }" name="doubleBed">
                    <a-input v-model:value="form.doubleBed" placeholder="请输入双床数量" />
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form-item>
            <!-- 星级要求-->
            <a-form-item label="星级要求" :label-col="{ span: 4 }" name="starLevel">
              <a-row>
                <a-col :span="16">
                  <a-checkbox-group v-model:value="form.starLevel">
                    <a-checkbox value="5">五星/豪华</a-checkbox>
                    <a-checkbox value="4">四星/高档</a-checkbox>
                    <a-checkbox value="3">三星/舒适</a-checkbox>
                    <a-checkbox value="2">二星/经济</a-checkbox>
                  </a-checkbox-group>
                </a-col>
              </a-row>
            </a-form-item>

            <!-- 附加需要-->
            <a-form-item label="附加需要" :label-col="{ span: 4 }" name="additionalNeeds">
              <a-row>
                <a-col :span="16">
                  <a-checkbox-group v-model:value="form.additionalNeeds">
                    <a-checkbox value="1">用餐</a-checkbox>
                    <a-checkbox value="2">用车</a-checkbox>
                    <a-checkbox value="3">会场</a-checkbox>
                    <a-checkbox value="4">导服</a-checkbox>
                  </a-checkbox-group>
                </a-col>
              </a-row>
            </a-form-item>

            <!-- 联系人信息-->
            <a-form-item label="联系人" :label-col="{ span: 4 }">
              <a-row>
                <a-col :span="8">
                  <a-form-item name="contactName">
                    <a-input v-model:value="form.contactName" placeholder="请输入联系人姓名" style="width: 100%;" />
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="手机" :label-col="{ span: 8 }" name="number">
                    <a-input v-model:value="form.phone" type="number" placeholder="请输入手机号码" />
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form-item>

            <!-- 微信和QQ -->
            <a-form-item label="微信" :label-col="{ span: 4 }">
              <a-row>
                <a-col :span="8">
                  <a-input v-model:value="form.weChat" placeholder="请输入微信号" />
                </a-col>
                <a-col :span="12">
                  <a-form-item label="QQ" :label-col="{ span: 8 }">
                    <a-input v-model:value="form.qq" type="number" placeholder="请输入QQ号码" />
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form-item>


            <!-- 固话和邮箱-->
            <a-form-item label="固话" :label-col="{ span: 4 }">
              <a-row>
                <a-col :span="8">
                  <a-input v-model:value="form.landline" type="number" placeholder="请输入固话" />
                </a-col>
                <a-col :span="12">
                  <a-form-item label="邮箱" :label-col="{ span: 8 }">
                    <a-input v-model:value="form.email" type="email" placeholder="请输入邮箱" />
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form-item>

            <!-- 意向酒店-->
            <a-form-item label="意向酒店" :label-col="{ span: 4 }">
              <a-row>
                <a-col style="width: 83%;">
                  <a-input v-model:value="form.intentionHotels" placeholder="请输入意向酒店 (多家酒店用加号隔开)" />
                </a-col>
              </a-row>
            </a-form-item>

            <!-- 其他补充 -->
            <a-form-item label="其他补充" :label-col="{ span: 4 }">
              <a-row>
                <a-col style="width: 83%;">
                  <a-textarea v-model:value="form.remark" placeholder="请输入补充信息" />
                </a-col>
              </a-row>
            </a-form-item>
            <!-- 提交按钮 -->
            <a-form-item>
              <a-row justify="center">
                <a-col>
                  <a-button type="primary" size="large" @click="onSubmit" style="width: 200px; font-size: 16px;">
                    提交
                  </a-button>
                </a-col>
              </a-row>
            </a-form-item>


          </a-form>
        </div>

      </div>
    </div>
  </section>
  <!--热卖推荐-->
  <section class="hotel_hotsale_section">

    <a-tabs v-model="hotCities" class="hot_house">
      <a-tab-pane tab="热卖酒店">
        <a-row :gutter="16">
          <a-col :span="6" v-for="data in hotCities" :key="data.HotelId">
            <a :href="`/hotel/detail?hotelId=` + data.HotelId" class="hot_house_piece">

              <a-card hoverable>
                <template #cover>
                  <a-image :preview="false" :alt="data.HotelName" :src="data.PhotoUrl"
                    fallback="https://img.etxing.com/b2b/images/hotelDefault.svg" />
                </template>
                <a-card-meta :title="data.HotelName">
                  <template #description>

                    <div class="house_info">

                      <a-rate :value="data.Star" disabled style="font-size: 12px"></a-rate>
                      <p><span class="hot_price">￥{{ data.LowestPrice }}</span><span class="hot_site">{{ data.HotelArea
                          }}</span></p>
                    </div>

                  </template>
                </a-card-meta>
              </a-card>
            </a>
          </a-col>
        </a-row>
      </a-tab-pane>
    </a-tabs>

  </section>
</template>

<script>
import '@/assets/styles/home.css'
import { Form, Select, Row, Col, FormItem, Button, Input, DatePicker, Checkbox, InputNumber } from 'ant-design-vue'
import dayjs from 'dayjs'
import address from '@/assets/js/address.json';
// import router from "@/assets/router";
export default {
  name: 'HomePage',
  data() {
    return {
      form: {
        country: null,
        province: null,
        city: null,
        area: null,
        checkInDate: null,
        checkOutDate: null,
        budget: 0,
        checkInPerson: 1,
        teamNature: "",
        roomQuantity: 1,
        bedType: 1,
        doubleBed: 0,
        starLevel: [],
        additionalNeeds: [],
        contactName: "",
        phone: "",
        weChat: "",
        qq: "",
        landline: "",
        email: "",
        intentionHotels: "",
        remark: ""
      },
      rules: {
        country: [{ required: true, message: '请选择国家', trigger: 'blur' }],
        province: [{ required: true, message: '请选择省份', trigger: 'blur' }],
        city: [{ required: true, message: '请选择城市', trigger: 'blur' }],
        // area: [{ required: true, message: '请选择区域', trigger: 'blur' }],
        //required、pattern、min、max
        budget: [{ required: true, message: '预算金额是必填项', trigger: 'blur' },],
        checkInPerson: [
          { required: true, message: '入住人数是必填项', trigger: 'blur' },
          { pattern: /^[1-9]\d*$/, message: '入住人数必须是正整数', trigger: 'blur' }
        ],
        roomQuantity: [
          { required: true, message: '房间数量是必填项', trigger: 'blur' },
          { pattern: /^[1-9]\d*$/, message: '房间数量必须是正整数', trigger: 'blur' }
        ],
        bedType: [
          { required: true, message: '大床数量是必填项', trigger: 'blur' },
          { pattern: /^[1-9]\d*$/, message: '大床数量必须是正整数', trigger: 'blur' }
        ],
        doubleBed: [
          { required: true, message: '双床数量是必填项', trigger: 'blur' },
          { pattern: /^[1-9]\d*$/, message: '双床数量必须是正整数', trigger: 'blur' }
        ],
        contactName: [
          { required: true, message: '联系人是必填项', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '手机是必填项', trigger: 'blur' },
        ],
        starLevel: [
          { required: true, message: '星球要求是必填', trigger: 'blur' },
        ],
        teamNature: [
          { required: true, message: '团队性质是必填', trigger: 'blur' },
        ],
        checkInDate: [
          { validator: this.checkInDateValidator, trigger: 'blur' }
        ],
        checkOutDate: [
          { validator: this.checkOutDateValidator, trigger: 'blur' }
        ]
      },
      countries: [],  // 国家列表
      provinces: [],  // 省份列表
      cities: [],      // 城市列表
      areas: [],       // 区域列表
      bannerActive: 0,
      bannerCarousels: [],
      groupImg: "",
      hotCities: []  // 保存随机选出的城市数据
    }
  },
  components: {
    'a-form': Form,
    'a-form-item': FormItem,
    'a-select': Select,
    'a-select-option': Select.Option,
    'a-row': Row,
    'a-col': Col,
    'a-button': Button,
    'a-input': Input,
    'a-input-number': InputNumber,
    'a-date-picker': DatePicker,
    'a-checkbox': Checkbox,
    'a-checkbox-group': Checkbox.Group,
    'a-textarea': Input.TextArea
  },
  created() {
    this.countries = address;
  },
  mounted() {
    this.fetchCarousel();
    this.fetchHotSale();
    document.title = this.$route.meta.title;
  },
  methods: {
    fetchCarousel() {
      this.$axios.post('/carousel', { "BannerType": "wholesaleroom,groupad", "Status": "1" })
        .then(response => {

          if (response.data.RetCode == "Success") {
            this.bannerCarousels = response.data.Banners.filter(banner => banner.BannerType === "wholesaleroom")
            this.bannerActive = 0;
            this.groupImg = response.data.Banners.filter(banner => banner.BannerType === "groupad")[0].NewsPhoto;
          } else {
            console.log(response.data)
          }

        });
    },
    fetchHotSale() {
      this.$axios.get('/hotSale')
        .then(response => {
          if (response.data.RetCode == "Success") {
            //console.log("response", response);
            const hotCityData = response.data.HotHotels || [];  // 如果为 undefined，则使用空数组
            const hotCityData_ = response.data.OverSeaHotHotels || [];  // 同上
            // console.log("hotCityData", hotCityData);
            //console.log("hotCityData_", hotCityData_);
            // 合并两个数据源
            const hotCity_Data = hotCityData.concat(hotCityData_);
            //  console.log("hotCity_Data", hotCity_Data);
            // 获取6个随机项并更新 hotCities
            this.hotCities = this.getArrayItems(hotCity_Data, 8);
            // console.log("hotCities", this.hotCities);
          } else {
            console.log(response.data)
          }

        });
    },

    // 禁用小于今天的日期
    disabledCheckInDate(current) {
      // current 是当前日期
      return current && current < dayjs().startOf('day'); // 禁用今天之前的日期
    },

    // 禁用小于入住日期的退房日期
    disabledCheckOutDate(current) {
      const { checkInDate } = this.form;
      if (!checkInDate) return false; // 如果入住日期为空，退房日期不禁用

      return current && current <= dayjs(checkInDate).endOf('day'); // 禁用小于入住日期的退房日期
    },

    // 入住日期验证，不能小于今天
    checkInDateValidator(rule, value) {
      return new Promise((resolve, reject) => {
        if (!value) {
          reject('入住日期是必填项');
        } else {
          // 获取今天的日期，时分秒都设为 0
          // console.log(value);
          const today = new Date();
          //today.setHours(0, 0, 0, 0); // 重置时分秒

          // 比较入住日期的年月日部分
          if (value < today) {
            reject('入住日期不能小于今天');
          } else {
            resolve();
          }
        }
      });
    },

    // 退房日期验证，必须大于入住日期
    checkOutDateValidator(rule, value) {
      return new Promise((resolve, reject) => {
        if (!value) {
          reject('退房日期是必填项');
        } else if (value <= this.form.checkInDate) {
          reject('退房日期必须大于入住日期');
        } else {
          resolve();
        }
      });
    },
    // 随机从数组中选取 num 个不重复项
    getArrayItems(arr, num) {
      const temp_array = [...arr];  // 创建一个数组副本
      const return_array = [];
      for (let i = 0; i < num; i++) {
        if (temp_array.length > 0) {
          const arrIndex = Math.floor(Math.random() * temp_array.length);
          return_array.push(temp_array.splice(arrIndex, 1)[0]);  // 从数组中移除并返回
        } else {
          break;
        }
      }
      return return_array;
    },

    // 根据国家ID加载省份
    onCountryChange(value) {
      const selectedCountry = this.countries.find(country => country.value === value);
      this.form.province = null;
      this.form.city = null;
      this.form.area = null;
      if (selectedCountry && selectedCountry.children) {
        this.provinces = selectedCountry.children;
      }
    },

    // 根据省份ID加载城市
    onProvinceChange(value) {
      this.form.city = null;
      this.form.area = null;
      // 根据选择的省份更新城市
      const selectedProvince = this.provinces.find(province => province.value === value);
      if (selectedProvince && selectedProvince.children) {
        this.cities = selectedProvince.children;
      }
    },

    // 根据城市ID加载区域
    onCityChange(value) {
      this.form.area = null;
      // 根据选择的省份更新区域
      const selectedCity = this.cities.find(city => city.value === value);
      if (selectedCity && selectedCity.children) {
        this.areas = selectedCity.children;
      }
    },

    // 提交按钮点击事件
    onSubmit() {
      this.$refs.formRef.validate()
        .then(() => {
          // 手动校验国家、省份、城市和区域字段
          if (!this.form.country || !this.form.province || !this.form.city) {
            this.$message.error('请选择完整的地区信息!');
            return;
          }
          //后端接收的是地区名称这里需要转换
          const countryName = this.countries.find(country => country.value === this.form.country).label;
          const provinceName = this.provinces.find(province => province.value === this.form.province).label;
          const cityName = this.cities.find(city => city.value === this.form.city).label;

          var areaName = "";
          if (this.form.area) {
            areaName = this.areas.find(area => area.value === this.form.area).label;
          }
          var bedType = this.form.bedType + "," + this.form.doubleBed;
          var starLevel = this.form.starLevel.join(",");
          var additionalNeeds = this.form.additionalNeeds.join(",");

          var data = {
            TeamType: this.form.teamNature,
            MembersId: 0,
            CityName: cityName,
            ProvinceName: provinceName,
            CountryName: countryName,
            DistrictName: areaName,
            Budget: this.form.budget,
            CheckIn: this.form.checkInDate,
            CheckOut: this.form.checkOutDate,
            OccupantNum: this.form.checkInPerson,
            RoomCount: this.form.roomQuantity,
            BedType: bedType,
            StarRequired: starLevel,
            Contacts: this.form.contactName,
            CellPhone: this.form.phone,
            Telephone: this.form.landline,
            Email: this.form.email,
            Wechat: this.form.weChat,
            QQ: this.form.qq,
            IntentionHotel: this.form.intentionHotels,
            Remark: this.form.remark,
            AdditionalDemand: additionalNeeds
          };

          this.$axios.post('/teamRooms', data)
            .then(response => {
              if (response.data.RetCode === "Success") {
                this.$success({
                  title: '恭喜您',
                  content: '提交团房需求成功,我们将在10分钟内确认需求并提供方案,请耐心等候我们联系您！',
                  okText: '关闭',
                  onOk() {
                    setTimeout(() => {
                      window.location.reload();
                    }, 100);
                  }
                });
              }
              else {

                var message = response.data.RetMessage || "";
                this.$error({
                  title: '啊哦~',
                  content: message,
                  okType: 'danger',
                  okText: '请刷新重试',
                  onOk() {
                    setTimeout(() => {
                      window.location.reload();
                    }, 100);
                  },
                });
              }
            }).catch(error => {
              console.error("Error fetching hotel rates:", error);
            });
        })
        .catch(() => {
          // 验证失败
          console.log('表单验证失败');
        });
    },
  }
}
</script>


<style scoped>
.hotel_group_section {
  background: #fff;
}

.group_bady {
  width: 1190px;
  margin: 0 auto;
}

.group_bady .group_title {
  position: relative;
  height: 80px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 40px;
}

.group_bady .group_title h2 {
  font-size: 22px;
  line-height: 80px;
  color: #333;
}

.group_bady .group_title h2 span {
  font-size: 14px;
  color: #669933;
}

.group_bady .group_title h2 i {
  font-size: 30px;
  padding-right: 10px;
  position: relative;
  top: 2px;
}

.group_bady .group_title p {
  position: absolute;
  top: 40px;
  right: 0px;
  font-size: 14px;
  color: red;
}

.group_form {
  /* width: 860px; */
  padding-left: 350px;
  position: relative;
}

.group_form .group_img {
  position: absolute;
  top: 0px;
  left: -20px;
}

.group_form .group_img img {
  width: 340px;
  height: 600px;
  border: 1px solid #eee;
}

.group_form .group_img .swiper-container {
  width: 350px;
  height: 630px;
  padding: 10px 50px 50px 50px;
}

.group_form .group_img .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 350px;
  height: 630px;
}
</style>